// NPM PACKAGES

// import jQuery from 'jquery'
// window.$ = window.jQuery = jQuery

import 'jquery'
import 'popper.js'
import 'bootstrap'


// import 'swiper'
import Swiper from 'swiper'
global.Swiper = Swiper;


import '@fancyapps/fancybox'

import 'scrollport-js'
import Headroom from "headroom.js";


// PROJECT JS MODULES
import {FormManager} from './modules/form';
import {SeoLink} from './modules/seolink';
import {MobileMenu} from './modules/mobile-menu';
// import {ScrollPort} from './modules/scrollport';



// CACHE ELEMENTS
const mobileMenu = new MobileMenu("#mobile-menu");

window.addEventListener('scroll', () => {

    if ($(window).scrollTop() > 500) {
        $('#headroom').removeClass('headroom--unpinned').addClass('headroom--pinned');
        $('.scroll-top-wrapper').addClass('show');
    } else {
        $('#headroom').removeClass('headroom--pinned').addClass('headroom--unpinned');
        $('.scroll-top-wrapper').removeClass('show');
    }
});

document.addEventListener("DOMContentLoaded", () => {

    // FORM
    const forms = new FormManager('form');
    forms.init();


    // DROPDOWN
    $(document).on('click', '.dropdown--freeze', function (e) {
        e.stopPropagation();
    });


    // ScrollTo
    $(document).on('click', '.scrolltop', function (e) {
        e.preventDefault();
        e.stopPropagation();
        const verticalOffset = typeof(verticalOffset) !== 'undefined' ? verticalOffset : 0;
        const element = $('body');
        const offset = element.offset();
        const offsetTop = offset.top;

        $('html, body').animate({scrollTop: offsetTop}, 200, 'linear');
    });

    // Menu

    mobileMenu.init();

});

window.onresize = () => {
    mobileMenu.resize();
};

window.onload = () => {
    // SEO HIDE for links
    const seoLinks = new SeoLink('[data-link]');
    seoLinks.init();




    // ScrollPort
    // $(document).on('click', '.js-scrollport', function (e) {
    //     e.preventDefault();
    //     ScrollPort.init(this);
    // });
    $(document).on('click', '.js-scrollport', function (e) {

        e.preventDefault();

        let $link = $(this),
            mode = $link.data('mode') || 'roll',  // 'usual', 'roll', 'hard', 'soft'
            distance = $link.data('distance') || 100,
            delta = $link.data('delta') || 100,
            targetStr = $link.data('href') || $link.data('target') || $link.attr('href'),
            targetTab = $link.data('show-tab') || false,
            targetCollapse = $link.data('show-collapse') || false;

        // console.log(target);

        if (!targetStr) {
            return true;
        }

        let target = '';

        let targetParts = targetStr.split('#');
        if (targetParts.length) {
            target = '#' + targetParts[targetParts.length - 1];
        }

        if ($.fn.scrollport && target && $(target).length && target.match("^#") && $(target).length) {

            if (targetTab && $(targetTab).length && $(targetTab)) {
                $(targetTab).tab('show');
            }

            $.scrollport($(target), {
                mode: mode,
                distance: distance,
                delta: delta
            });

            // console.log($(targetTab).hasClass('tab-pane'));
        }

        if (targetCollapse && $(targetCollapse).length && $(targetCollapse)) {
            $(targetCollapse).collapse('show');
        }
    });

    $().fancybox({
        selector: '[data-toggle="lightbox"]',
        // selector: '[data-fancybox]',
        hash: false,
        protect: false,
        backFocus : false,
        buttons: [
            "thumbs",
            "close"
        ],
        // caption : function( instance, item ) {
        //     return $(this).parent('figure').find('figcaption').html();
        // }
    });
};

$(function () {

});




// PROJECT STYLESHEETS
import '../scss/app.scss';
