import Inputmask from "inputmask";


export class FormManager {



    constructor(element, options) {

        this.defaults = {

        };

        this.action = 'index.php?route=leadgen/form/send';

        this.selector = {
            outer : '.ajaxFormBox',
            success : '.ajaxSuccessBox',
            ymGoal : 'input[name="ymGoal"]',
        };

        this.reachGoalTypes = {
            ym : 'yandex',
            ga : 'google'
        };

        this.ymCounterID = null;
        this.ymCounter = {};


        this.options = $.extend({}, this.defaults, options);

        this.$form = $(element);

        const im = new Inputmask("+7 (999) 999-99-99");
        const phoneSelector = document.querySelectorAll('input[type="tel"]');
        im.mask(phoneSelector);
    }

    init() {

        this._getYmId();

        $(document).on('focus', '.was-validated :input', () => {
            $(this).addClass('validate-updated');
        });

        $(document).on('keyup', '.is-invalid :input', () => {
            $(this).closest('.is-invalid').removeClass('is-invalid');
        });


        this.$form.on('submit', (e) => {
            e.preventDefault();

            const $form = $(e.currentTarget);

            const validate = this._validateForm($form);

            if (!validate) {
                e.preventDefault();
                e.stopPropagation();
                return false;
            }

            this._sendForm($form);
        });


    }

    _appendData($form) {
        let data = '';

        console.log($form);

        console.log($form.find('input[name="page_url"]').val());

        let page_url = $form.find('input[name="page_url"]').val();
        if (!page_url) {
            data += '&page_url=' + encodeURIComponent(window.location.href);
        }

        let page_name = $form.find('input[name="page_name"]').val();
        if (!page_name) {
            const $title_h1 = $('h1');
            if(!$title_h1.length ) {
                data += '&page_name=' + encodeURIComponent(document.title);
            } else {
                data += '&page_name=' + encodeURIComponent($title_h1.html());
            }
        }

        return data;
    }

    _sendForm($form) {

        this._removeErrors();

        const ymCounterID = this.ymCounterID;
        const ymGoal = $form.find(this.selector.ymGoal).val() || false;
        const $container = $form.closest(this.selector.outer);
        const $successContainer = $form.closest(this.selector.success);

        let data = $form.serialize() + this._appendData($form);

        $.ajax({
            url: this.action,
            method: 'post',
            data: data,
            resetForm: 'true',
            beforeSend: () => {
                $form.find('button[type="submit"]').button('loading').attr("disabled", true);
                $form.find('.error').remove();
                $form.find('.errorValue').removeClass('errorValue');
            },
            complete: () => {
                $form.find('button[type="submit"]').button('reset').attr("disabled", false);
            },
            success: (response) => {

                // console.log(response);

                if (response.error) {
                    for (let field in response.error) {

                        const $formGroup = $form.find("[name='" + field.replace('error_', '') + "']").closest('.form-group');

                        $formGroup.addClass('is-invalid').find('.invalid-feedback').html(response.error[field]);
                    }
                } else if (response.success) {

                    if ($successContainer.length) {
                        $successContainer.removeClass('d-none').html(response.success);
                    } else {
                        $container.html(response.success);
                    }

                    $container.addClass('submitted');

                    if (ymCounterID && ymGoal) {
                        this._sendReachGoal(this.reachGoalTypes.ym, ymCounterID, ymGoal);
                    }
                }
            }
        });
    }

    _removeErrors() {
        // this.$form.find('.validate-updated').removeClass('validate-updated');
        // this.$form.find('.is-invalid').remove('is-invalid');
    }

    _validateForm($form) {

        if (!$form.hasClass('needs-validation')) {
            return true;
        }

        let hasErrors = false;

        if ($form[0].checkValidity() === false) {
            // event.preventDefault();
            // event.stopPropagation();
            hasErrors = true;
        }

        $form.find('.validate-updated').removeClass('validate-updated');
        $form.find('.is-invalid').removeClass('is-invalid');

        $form.find('.required textarea[data-toggle=\'summernote\']').each(function (i) {

            const $parent = $(this).closest('.form-group');
            if (!$(this).val() && $parent.length) {
                $parent.addClass('has-error');

                console.log('Empty');

                hasErrors = true;
            }
        });

        $form.find('.star-rating.required').each(function (i) {

            const $parent = $(this).closest('.form-group');
            if (!$(this).find('input:checked').length && $parent.length) {
                $parent.addClass('has-error');

                console.log('Empty');

                hasErrors = true;
            }
        });

        $form.addClass('was-validated');

        return !hasErrors;
    }

    _sendReachGoal(type, ymCounterID, ymGoal) {

        if (!ymCounterID) {
            console.log('Error Yandex Metrika CounterId');
        } else {
            try {
                this.ymCounter[ymCounterID] = new Ya.Metrika2({id: ymCounterID});
                this.ymCounter[ymCounterID].reachGoal(ymGoal);
                console.log('send reachGoal OK');
                return true;
            } catch (e) {
                console.log('Error Yandex Metrika reachGoal');
            }
        }
    }

    _getYmId() {

        let yCounter = this._getMetaTag('ymc_id');

        // if (!yCounter) {
        //     window.onload = () => {
        //         const myRegexp = /ym\((.+?), \"init\"/;
        //         for (let i = 0; i < document.scripts.length; i++) {
        //             let match = myRegexp.exec(document.scripts[i].text);
        //             if (match) {
        //                 yCounter = match[1];
        //                 break;
        //             }
        //         }
        //     };
        // }

        this.ymCounterID = yCounter;
    }

    _getMetaTag(metaName) {
        const metas = document.getElementsByTagName('meta');

        for (let i = 0; i < metas.length; i++) {
            if (metas[i].getAttribute('name') === metaName) {
                return metas[i].getAttribute('content');
            }
        }
        return '';
    }
}