export class SeoLink {

    constructor(linkSelector) {
        this.$links = $(linkSelector);
        this.windowWidth = SeoLink.getActualWidth();
    }

    init() {

        const thisModule = this;

        this.$links.each(function () {

            const arr = $.map(this.attributes, function (attribute) {
                if (attribute.name !== 'data-link' && attribute.name !== 'data-content' && attribute.name !== 'data-target-attr') {
                    return attribute.name + '="' + attribute.value + '"';
                }
            });

            let hrefValue = $(this).data("content");

            let targetAttribute = '';
            if ($(this).data('target')) {
                targetAttribute = ' target="' + $(this).data('target') + '"';
            }


            const linkType = $(this).data("link");
            switch (linkType) {
                case 'link':
                    break;

                case 'anchor':
                    hrefValue = hrefValue || '#';
                    break;

                case 'mailto':
                    hrefValue = 'mailto:' + hrefValue;
                    break;

                case 'tel':
                    if (thisModule.windowWidth <= 1024) {
                        hrefValue = 'tel:' + hrefValue;
                    } else {
                        hrefValue = false;
                    }

                    break;

                case 'for-mobile':
                    if (thisModule.windowWidth > 1024) {
                        hrefValue = false;
                    }
                    break;

                default:
                    hrefValue = false;
                    break;
            }


            if (hrefValue) {
                $(this).replaceWith('<a ' + arr.join(' ') + ' href="' + hrefValue + '"' + targetAttribute + '>' + $(this).html() + '</a>');
            }

        })
    }

    static getActualWidth() {
        return $(window).width();

    }


}